import './App.css';

import React from 'react';
import { Routes, Route, Navigate, BrowserRouter } from 'react-router-dom'
import { Outlet } from 'react-router-dom';

import { Loader, SearchBar, Header, Footer, Sidebar, ThemeController, Page, AppWrapper } from './Overux'

import { BookingsPage, BookingModal } from './BookingsPage'
import { AccountPage } from './AccountPage'
import { ContactPage } from './ContactPage'
import { LoginPage } from './LoginPage'
import { RegisterPage } from './RegisterPage'

import { accountInfo } from './api'

let AppLayout = ({firstname}) => (
  <>
  <div>
    <Sidebar firstname={firstname} />
    <Page>
      <Sidebar />
    <Outlet />
    </Page>
    <BookingModal />
    <ThemeController />
  </div>
  </>
);

class App extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			firstname: "",
			lastname: ""
		};
		accountInfo().then(this.recieveAccountInfo);
	}

	recieveAccountInfo = (data) => {
		if (data["msg"]) {
		var info = data["msg"];
		this.setState({
			firstname: info["firstname"],
			lastname: info["lastname"]
		});
		}
	}

  // This was useful: https://stackoverflow.com/questions/71185633/react-router-v6-how-to-render-multiple-component-inside-and-outside-a-div-with
  render() {
   return (
     <BrowserRouter>
     <Routes>
     <Route path="/login" element={<LoginPage />} />
     <Route path="/register" element={<RegisterPage />} />
     <Route>
     <Route element={<AppLayout firstname={this.state.firstname} />}>
        <Route exact path="/" element={<Navigate to="/bookings" />} />
        <Route path='/bookings' element={<BookingsPage />} />
        <Route path='/account' component={<AccountPage />} />
        <Route path='/contact' component={<ContactPage />} />
      </Route>
      </Route>
      </Routes>
     </BrowserRouter>
   ) 
  }
}

export default App;
