const apiURL = 'https://api.makerspacedelft.nl/prod';

// Example POST method implementation:
async function postData(url = '', data = {}, method = 'POST') {
    //Default options are marked with *
    const response = await fetch(url, {
        method: method, // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        headers: {
            'Content-Type': 'application/json'
            // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        credentials: 'include',
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: JSON.stringify(data) // body data type must match "Content-Type" header
    });
    return response.json(); // parses JSON response into native JavaScript objects

}

function signin(email, password) {
    // todo: disable form.
    try {
        if (email.length == 0 || password.length == 0) return new Promise(function () { });
        return postData(apiURL + '?action=client', { email: email, password: password })
            .then(data => {
                if (data["code"] == "LOGIN") {
                    return true;
                }
                else {
                    alert("Sign in failed: " + data["msg"]);
                    return false;
                }
                // todo: enable form
            });
    } catch (e) { alert(e); }
}

function signout() {
    // todo: delete cookies
    return true;
}

function register(email, password, firstname, lastname) {
    try {
        // todo: disable form.
        return postData(apiURL + '?action=client', { email: email, newpassword: password, firstname: firstname, lastname: lastname, createaccount: true })
            .then(data => {
                if (data["code"] == "REG") {
                    return true;
                }
                else {
                    alert("registration failed: " + data["msg"]);
                    return false;
                }
                // todo: enable form
            });
    } catch (e) { console.log(e); }
}

function setUserData(email, userData) {
    try {
        // todo: disable form.
        return postData(apiURL + '?action=client', { email: email, userdata: userData, update: true })
            .then(data => {
                if (data["code"] == "UPDATE") {
                    return true;
                }
                else {
                    alert("update failed: " + data["msg"]);
                    return false;
                }
                // todo: enable form
            });
    } catch (e) { console.log(e); }
}

function getUserData() {

}

function registerBooking(date, start, end, people, description = "") {
    try {
        return postData(apiURL + '?action=bookings', { "date": date, "start": start, "end": end, "npeople": people, "description": description})
            .then(data => {
                if (data["code"] == "BOOKED") {
                    return true;
                }
                else {
                    alert("Registration failed: " + data["msg"]);
                    return false;
                }
                // todo: enable form
            });
    } catch (e) { console.log(e); }
}

function accountInfo() {
    return fetch(apiURL + '?action=client', { credentials: 'include' }).then(readResponse);
}

function getAvailability(from, to) {
    return fetch(apiURL + '?action=bookings&see=1&from='+from+'&to='+to, { credentials: 'include' }).then(readResponse);
}

function dateString(n) {
    var d = new Date();
    d.setDate(d.getDate() + n);
    return d.toISOString().replace(/T.*/, '');
}

function dateId(n) {
    var d = new Date();
    d.setDate(d.getDate() + n);
    return d.valueOf() - (new Date(2020, 1, 1)).valueOf();
}

function formatDate(d) {
    var months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    var days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    return days[d.getDay()] + ", " + months[d.getMonth()] + " " + d.getDate();
}

function formatDateId(dateId) {
    var d = new Date(parseInt(dateId)+(new Date(2020, 1, 1)).valueOf());
    return formatDate(d);
}

function readResponse(response) {
	if (response.status == 401 && window.location.pathname !== "/login") {
		window.location = "/login";
		return false;
	}
	return response.json();
}

function bookings() {
    return fetch(apiURL + '?action=bookings&from=' + dateId(0) + '&to=' + dateId(365), { credentials: 'include' }).then(readResponse);
}

function cancelBooking(id) {
    if (window.confirm("Are you sure you want to cancel this booking?")) {
        postData(apiURL + '?action=bookings', { "id": id }, 'DELETE');
/*        if (reload)
        {
            document.getElementById('loader').style['display'] = "flex";
            setTimeout(function() {        
                reload();
                loadForm();
                document.getElementById('loader').style['display'] = "none";
            },250)
        }*/
    }
}

export { getAvailability, dateString, formatDate, bookings, cancelBooking, registerBooking, signin, register, accountInfo, setUserData, getUserData }
