import React from 'react';
import { register } from './api';

class RegisterPage extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			username: "",
			password: "",
			password2: "",
			firstname: "",
			lastname: "",
			code: ""
		};
	}

  handleChangeUsername = (event) => {
    this.setState({username: event.target.value});
  }

  handleChangeCode = (event) => {
    this.setState({code: event.target.value});
  }

  handleChangePassword = (event) => {
    this.setState({password: event.target.value});
  }

  handleChangePassword2 = (event) => {
    this.setState({password2: event.target.value});
  }

  handleChangeFirstName = (event) => {
    this.setState({firstname: event.target.value});
  }

  handleChangeLastName = (event) => {
    this.setState({lastname: event.target.value});
  }

  submit = (e) => {
    e.preventDefault();
	  if (this.state.code != "HE8SXL4")
	  {
		  alert("Incorrect code");
		  return;
	  }
	  if (!this.state.username.length)
	  {
		  return;
	  }
	  if (this.state.password != this.state.password2)
	  {
		  alert("Passwords do not match.");
		  return;
	  }
	  register(this.state.username, this.state.password, this.state.firstname, this.state.lastname).then( result => { if (result) window.location = "#account" ; setTimeout(window.prepareDocument,500); });
  }

  render() {
    return (
              <div class="page">
            <div class="page-content h-100">
                <div class="background theme-header"><img src="img/city2.jpg" alt="" /></div>
                <div class="row mx-0 h-100 justify-content-center">
                    <div class="col-10 col-md-6 col-lg-4 my-3 mx-auto text-center align-self-center">
                        <img src="img/logo-w.png" alt="" class="login-logo" />
                        <br /><br />
                        <h5 class="text-white mb-4">Register with us</h5>
    <form onSubmit={this.submit} name="login">
                        <div class="login-input-content ">
                            <div class="form-group">
                                <input type="text" value={this.state.code} onChange={this.handleChangeCode} class="form-control rounded text-center" placeholder="Registration code" />
                            </div>
                            <div class="form-group">
                                <input type="text" value={this.state.firstname} onChange={this.handleChangeFirstName} class="form-control rounded text-center" placeholder="First name" />
                            </div>
                            <div class="form-group">
                                <input type="text" value={this.state.lastname} onChange={this.handleChangeLastName} class="form-control rounded text-center" placeholder="Last name" />
                            </div>
                            <div class="form-group">
                                <input type="text" value={this.state.username} onChange={this.handleChangeUsername} class="form-control rounded text-center" placeholder="Email" />
                            </div>
                            <div class="form-group">
                                <input type="password" value={this.state.password} onChange={this.handleChangePassword} class="form-control rounded text-center" placeholder="Password" />
                            </div>
                            <div class="form-group">
                                <input type="password" value={this.state.password2} onChange={this.handleChangePassword2} class="form-control rounded text-center" placeholder="Password" />
                            </div>
                            <input type="submit" class="btn btn-block btn-success rounded border-0 z-3" value="Sign up" />
                        </div>
                        </form>
                        <br />
                        <br />
                        <div class="row no-gutters">
                            <div class="col-12 text-center text-white">Already have account?<br />Please <a href="#login" class="text-white font-weight-bold mt-3">Sign In</a> here.</div>
                        </div>                        


                    </div>
                </div>

                <br />

            </div>

        </div>
    )
  }
}

export { RegisterPage }
