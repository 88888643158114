import React from 'react';
import  { NavLink } from 'react-router-dom';

function Loader() {
  return (
    <div id="loader" class="loader justify-content-center ">
      <div class="maxui-roller align-self-center">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  )
}

function SearchBar() {
 return (
   <form class="searchcontrol">
     <div class="input-group">
       <div class="input-group-prepend">
         <button type="button" class="input-group-text close-search"><i class="material-icons">keyboard_backspace</i></button>
       </div>
       <input type="email" class="form-control border-0" placeholder="Search..." aria-label="Username" />
     </div>
   </form>
 )
}

function Header() {
 return (
   <header class="row m-0 fixed-header">
     <div class="left">
       <a href="javascript:void(0)" class="menu-left"><i class="material-icons">menu</i></a>
     </div>
     <div class="col center">
       <a href="index.html" class="logo">
         <figure><img src="img/logo-w.png" alt="" /></figure> My Bookings</a>
     </div>
     <div class="right">
       <a href="javascript:void(0)" class="searchbtn"><i class="material-icons">search</i></a>
     </div>
   </header>
 ) 
}

function Footer() {
  return (
    <div class="footer-wrapper shadow-15">
      <div class="footer">
        <div class="row mx-0">
          <div class="col">
            Overux
            </div>
          <div class="col-7 text-right">
            <a href="index.html" class="social"><img src="img/facebook.png" alt="" /></a>
            <a href="index.html" class="social"><img src="img/googleplus.png" alt="" /></a>
            <a href="index.html" class="social"><img src="img/linkedin.png" alt="" /></a>
            <a href="index.html" class="social"><img src="img/twitter.png" alt="" /></a>
          </div>
        </div>
      </div>
      <div class="footer dark">
        <div class="row mx-0">
          <div class="col  text-center">
            Makerspace Delft
           </div>
        </div>
      </div>
    </div>
  )
}

function ThemeController() {
  return (
    <div>
      <button class="theme btn btn-info sq-btn rounded float-bottom-right" data-toggle="modal" data-target="#colorscheme"><i class="material-icons">color_lens</i></button>
      <div class="modal fade " id="colorscheme" tabindex="-1" role="dialog" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content ">
            <div class="modal-header border-bottom theme-header">
              <p class="text-uppercase font-weight-bold text-white my-2">Choose your color</p>
              <button type="button" class="close text-white" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">

              <div class="text-center theme-color">
                <p class="text-uppercase font-weight-bold text-dark text-center">Select Color</p>
                <button class="m-2 btn btn-danger rounded sq-btn text-white" data-theme="color-theme-red"><i class="material-icons w-25px">color_lens_outline</i></button>
                <button class="m-2 btn btn-primary rounded sq-btn text-white" data-theme="color-theme-blue"><i class="material-icons w-25px">color_lens_outline</i></button>
                <button class="m-2 btn btn-warning rounded sq-btn text-white" data-theme="color-theme-yellow"><i class="material-icons w-25px">color_lens_outline</i></button>
                <button class="m-2 btn btn-success rounded sq-btn text-white" data-theme="color-theme-green"><i class="material-icons w-25px">color_lens_outline</i></button>
                <br />
                <button class="m-2 btn btn-pink rounded sq-btn text-white" data-theme="color-theme-pink"><i class="material-icons w-25px">color_lens_outline</i></button>
                <button class="m-2 btn btn-orange rounded sq-btn text-white" data-theme="color-theme-orange"><i class="material-icons w-25px">color_lens_outline</i></button>
                <button class="m-2 btn btn-gray rounded sq-btn text-white" data-theme="color-theme-gray"><i class="material-icons w-25px">color_lens_outline</i></button>
                <button class="m-2 btn btn-black rounded sq-btn text-white" data-theme="color-theme-black"><i class="material-icons w-25px">color_lens_outline</i></button>
              </div>
              <hr class="mt-4" />
              <div class="row align-items-center mt-4">
                <div class=" col-12 w-100">
                  <p class="text-uppercase font-weight-bold text-dark text-center">Layout Mode</p>
                </div>
                <div class="col text-right"><i class="material-icons text-warning icon-3x">wb_sunny</i></div>
                <div class="col-auto text-center">
                  <input type="checkbox" id="theme-dark" class="switch" />
                  <label for="theme-dark">Toggle</label>
                </div>
                <div class="col text-left"><i class="material-icons text-dark icon-3x">brightness_2</i></div>
              </div>


            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

class Page extends React.Component {
  render() {
    return (
      <div class="page">
        <SearchBar />
        <Header />
        <div class="page-content">
          <div class="content-sticky-footer">
            {this.props.children}
          </div>
          <Footer />
        </div>
      </div>
    );
  }
}

function Sidebar(props) {
  return (

    <div class="sidebar sidebar-left">
    <div class="profile-link">
      <a href="#account" class="media" style={{"display":"true"}}>
        <div class="w-auto h-100">
          <figure class="avatar avatar-40"><img src="img/user1.png" alt="" /> </figure>
                    </div>
          <div class="media-body">
            <h5 class=" mb-0">{props.firstname} <span class="status-online bg-success"></span></h5>
            <p>Delft</p>
          </div>
                </a>
            </div>
      <nav class="navbar">
        <ul class="navbar-nav">
          <li class="nav-item">
            <NavLink to="/account" className="sidebar-close" activeClassName="active">
              <div class="item-title">
                <i class="material-icons">account_circle</i>Account
                            </div>
            </NavLink>
          </li>
          <li class="nav-item">
            <NavLink to="/bookings" className="sidebar-close" activeClassName="active">
              <div class="item-title">
                <i class="material-icons">menu</i>Bookings
                            </div>
            </NavLink>
          </li>
          <li class="nav-item">
            <NavLink to="/contact" className="sidebar-close" activeClassName="active">
              <div class="item-title">
                <i class="material-icons">add_location</i> Contact Us
                            </div>
            </NavLink>
          </li>
        </ul>
      </nav>
      <div class="profile-link text-center">
        <a href="./login" class="btn btn-link text-white btn-block">Logout</a>
      </div>
      </div>
  )
}

class AppWrapper extends React.Component {
  render() {
    return (
      <div className="App">
        <Loader />
        <div class="wrapper">
          {this.props.children}
        </div >
      </div>
    )
  }
}

export { Loader, SearchBar, Header, Footer, Sidebar, ThemeController, Page, AppWrapper };
