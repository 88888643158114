import React from 'react';

import { accountInfo, getUserData, setUserData } from './api'

class AccountPage extends React.Component {

	recieveData = (data) => {
		if (data) {
		var bookingsData = data["msg"];
		this.setState({
		});
		}

	}

	recieveAccountInfo = (data) => {
		if (data["msg"]) {
		var info = data["msg"];
		this.setState({
			firstname: info["firstname"],
			lastname: info["lastname"],
			email: info["key"],
		});
		this.setState(info["userData"]);
		}
	}

	constructor(props) {
		super(props);
		this.state = {
			firstname: "...",
			lastname: "...",
		};
		accountInfo().then(this.recieveAccountInfo);
	//getAvailability(dateString(0),dateString(14)).then(this.recieveData);
	}
	
	updateField = ( e, field ) => {
		var dict = {};
		dict[field] = e.target.value;
		this.setState(dict);
	};

	submit = (e) => {
		e.preventDefault();
		setUserData(this.state.email, this.state);
		//registerBooking(dateString(parseInt(this.state.selectedDay)), this.state.selectedStart, this.state.selectedEnd, this.state.npeople).then( () => { window.location.reload(); } );
		// window.$('#form').modal('hide');
		//setTimeout(() => { this.reload(); }, 1000);
		// window.location.reload();
	}

	render

	render() {

 return (
   <div class="no-top-gap">
   <div class="row mx-0 position-relative py-5 mb-4">
     <div class="background h-100 theme-header"><img src="img/background.png" alt="" /></div>
       <div class="col">
         <a href="user-profile.html#" class="media">
           <div class="w-auto h-100">
             <figure class="avatar avatar-120"><img src="img/user1.png" alt="" /> </figure>
                                </div>
             <div class="media-body align-self-center ">
               <h5 class="text-white">{this.state.firstname + " " + this.state.lastname} <span class="status-online bg-success"></span></h5>
               <p class="text-white">
                 online <span class="status-online bg-color-success"></span>
                 <br /> <span id="key">{this.state.email}</span>
                                    </p>
                                </div>
                            </a>
             </div>
           </div>


	 <form onSubmit={this.submit} name="login">

		<h2 class="block-title">Account Details:</h2>
           <div class="row mx-0">
             <div class="col">
			<div class="form-group">
			<label>Team name:</label>
			<input type="text" class="form-control" value={this.state.team} onChange={(e) => this.updateField(e, "team")} />
			</div>

			<div class="form-group">
			<label>Phone number:</label>
			<input type="text" class="form-control" value={this.state.phone} onChange={(e) => this.updateField(e, "phone")} />
			</div>
</div>
	 </div>

	 <div style={{display:"none"}}>
		<h2 class="block-title">Emergency information:</h2>
           <div class="row mx-0">
             <div class="col">
			<div class="form-group">
			<label>Allergies:</label>
			<input type="text" class="form-control" value={this.state.allergies} onChange={(e) => this.updateField(e, "allergies")} placeholder="None" />
			</div>

			<div class="form-group">
			<label>Medications:</label>
			<input type="text" class="form-control" value={this.state.medications} onChange={(e) => this.updateField(e, "medications")} placeholder="None" />
			</div>

			<div class="form-group">
			<label>Blood type:</label>
			<input type="text" class="form-control" value={this.state.bloodtype} onChange={(e) => this.updateField(e, "bloodtype")} placeholder="Unknown" />
			</div>
</div>
	 </div>
	 </div>

		<h2 class="block-title">Makerspace Profile:</h2>
           <div class="row mx-0">
             <div class="col">
			<div class="form-group">
			<label>About you: (interests, skills, hobbies) </label>
			<textarea type="text" class="form-control" value={this.state.bio} onChange={(e) => this.updateField(e, "bio")} />
			</div>

			<div class="form-group">
			<label>Instagram:</label>
			<input type="text" class="form-control" value={this.state.insta} onChange={(e) => this.updateField(e, "insta")} placeholder="" />
			</div>

			<div class="form-group">
			<label>Slack username:</label>
			<input type="text" class="form-control" value={this.state.slack} onChange={(e) => this.updateField(e, "slack")} />
			</div>

	 </div>
	 </div>
		<h2 class="block-title">Privacy:</h2>
           <div class="row mx-0">
             <div class="col">
			<div class="form-group">
			<input id="showemail" type="checkbox" class="" checked={this.state.showemail} onClick={(e) => this.setState({showemail:!!e.target.checked})} />
			<label for="showemail">&nbsp;Allow other members to see my email address</label><br />
			<input id="showphone" type="checkbox" class="" checked={this.state.showphone} onClick={(e) => this.setState({showphone:!!e.target.checked})} />
			<label for="showphone">&nbsp;Allow other members to see my phone number</label>
			</div>

			<input class="btn btn-block btn-primary btn-lg rounded border-0 z-3" type="submit" value="Save Profile" />

	 </div>
	 </div>

	 </form>

	 <br />
	 </div>
 ) 
}

}

export { AccountPage }
