function ContactPage() {
 return (
   <div class="no-top-gap">
           <div class="row mx-0 mt-3">
             <div class="col">

           <h2 class="block-title">Makerspace Delft</h2>
           <div class="row mx-0">
             <div class="col">
	 <p>
	 Makerspace Delft <br />
Schieweg 15D<br />2627 AN Delft, Netherlands
	 </p>
             </div>
           </div>
               <a href="https://makerspacedelft.slack.com/" target="_blank" class="btn btn-block btn-outline-secondary rounded">Slack</a>
               <a href="mailto:contact@makerspacedelft.nl" target="_blank" class="btn btn-block btn-outline-secondary rounded">Email</a>
             </div>
           </div>
   </div>

 ) 
}

export { ContactPage }
